export const menuList = [
  {
    name: "Vienna temple attack",
    path: "/shootout-at-ravidassia-temple-in-vienna/",
    submenu: []
  },
  {
    name: "Ravidassia Religion",
    path: "/what-is-ravidassia-religion/",
    submenu: []
  },

  {
    name: "About Guru Ravidass Ji",
    path: "/who-is-guru-ravidass/",
    submenu: [
      {
        name: "Aarti in English",
        path: "/aarti-guru-ravidass-english/"
      },
      {
        name: "Aarti in Hindi",
        path: "/aarti-guru-ravidass-hindi/"
      },
      {
        name: "Aarti in Punjabi",
        path: "/aarti-guru-ravidass-punjabi/"
      },
      {
        name: "Aarti Romanized",
        path: "/aarti-guru-ravidass-romanized/"
      },

      {
        name: "Amritbani",
        path: "/amritbani-guru-ravidass/"
      }
    ]
  },

  {
    name: "Dera Ballan",
    path: "/dera-ballan/history/",
    submenu: [
      {
        name: "Baba Pipal Dass Ji",
        path: "/dera-ballan/baba-pipal-dass/"
      },
      {
        name: "Sant Sarwan Dass Ji",
        path: "/dera-ballan/sant-sarwan-dass/"
      },
      {
        name: "Sant Hari Dass Ji",
        path: "/dera-ballan/sant-hari-dass/"
      },
      {
        name: "Sant Garib Dass Ji",
        path: "/dera-ballan/sant-garib-dass/"
      },
      {
        name: "Sant Niranjan Dass Ji",
        path: "/dera-ballan/sant-niranjan-dass/"
      },
      {
        name: "Sant Ramanand Ji",
        path: "/dera-ballan/sant-ramanand/"
      }
    ]
  },
  {
    name: "List of temples",
    path: "/list-of-ravidassia-temples-in-the-world/"
  },
  {
    name: "Guru Ravidass University",
    path: "/guru-ravidass-university/"
  },
  {
    name: "Guru Ravidass' birth place",
    path: "/birth-place-of-guru-ravidass/"
  },
  {
    name: "Guru Ravidass Jayanti dates",
    path: "/guru-ravidass-birth-anniversary-dates/"
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy/",
    submenu: []
  }
];
