import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { Link } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

import Cookies from "universal-cookie";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 0, 0, 2),
    padding: theme.spacing(1, 4),
    whiteSpace: "nowrap"
  },
  input: {
    display: "none"
  },
  link: {
    color: "#fff"
  }
}));

const CookiesConsentWrapper = styled("div")`
  position: fixed;
  right: 15px;
  bottom: 20px;
  height: auto;
  width: 400px;
  padding: 1em;
  color: #fff;
  background-color: #333;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 1px 5px 3px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 992px) {
    width: 100%;
    right: 0;
    bottom: 0;
    border-radius: 0px;
  }
`;

const CookiesConsent = ({ isConsentAgree }) => {
  const classes = useStyles();

  const [isCookiesAccepted, setIsCookiesAccepted] = useState(true);

  useEffect(() => {
    setIsCookiesAccepted(isCookiesConsentAccepted());
  },[]);

  const isCookiesConsentAccepted = () => {
    const cookies = new Cookies();
    return !!cookies.get("cookiesConsent");
  };

  const setCookiesConsent = () => {
    const cookies = new Cookies();
    cookies.set("cookiesConsent", "1", {
      path: "/",
      secure: false,
      httpOnly: false
    });

    setIsCookiesAccepted(!!cookies.get("cookiesConsent"));
  };

  return (
    !isCookiesAccepted && (
      <CookiesConsentWrapper>
        <span>
          This website uses cookies to ensure you get the best experience on our
          website.{" "}
          <Link to={`/privacy-policy/`} className={classes.link}>
            Learn more
          </Link>
        </span>

        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => setCookiesConsent()}
        >
          Got it!
        </Button>
      </CookiesConsentWrapper>
    )
  );
};

export default CookiesConsent;
