import React from "react";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import ShareIcon from "@material-ui/icons/Share";
import Sidebar from "../Sidebar/Sidebar";
import { isMobile } from "react-device-detect";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  title: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  }
}));

const AppHeader = () => {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const sharePage = () =>
    navigator.share({
      title: "Jai Gurdev, Please check this website",
      text: "Jai Gurdev, Please check this website",
      url: document.querySelector("link[rel='canonical']").href
    });

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            color="inherit"
            variant="h6"
            noWrap
            className={classes.title}
          >
            RavidassGuru.com
          </Typography>
          {isMobile && navigator.share && (
            <ShareIcon onClick={() => sharePage()} />
          )}
        </Toolbar>
      </AppBar>
      <Sidebar openDrawer={mobileOpen} close={() => handleDrawerToggle()} />
    </div>
  );
};

export default AppHeader;
