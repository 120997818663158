import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Link from "../../components/Link";
import * as React from "react";
import { menuList } from "./constants";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  navLink: {
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    textDecoration: "none",
    flex: 1,
    "&:hover": {
      textDecoration: "none"
    }
  },
  nested: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    color: theme.palette.primary.main,
    textDecoration: "none",
    flex: 1,
    "&:hover": {
      textDecoration: "none"
    }
  },
  li: {
    padding: theme.spacing(0)
  },
  activeClass: {
    color: "#ffffff",
    backgroundColor: theme.palette.primary.main
  }
}));

export default function Sidebar(props) {
  const { container, openDrawer, close } = props;
  const classes = useStyles();
  const theme = useTheme();

  const [open, setOpen] = React.useState(true);

  function handleClick() {
    setOpen(!open);
  }

  const drawer = (
    <>
      <div className={classes.toolbar} />
      <Divider />
      <List component="ul" aria-labelledby="nested-list-subheader">
        {menuList.map((menu, i) => {
          return (
            <React.Fragment key={i}>
              <ListItem
                className={classes.li}
                button
                component="li"
                onClick={handleClick}
              >
                {/* onClick={handleClick} */}
                <Link
                  className={classes.navLink}
                  to={menu.path}
                  activeClassName={classes.activeClass}
                >
                  <ListItemText primary={menu.name} />
                  {/*  {!!menu.submenu.length &&
                    (open ? <ExpandLess /> : <ExpandMore />)} */}
                </Link>
              </ListItem>
              {!menu.submenu
                ? null
                : menu.submenu.map((submenu, index) => {
                    return (
                      <Collapse
                        in={true}
                        timeout="auto"
                        unmountOnExit
                        key={index}
                      >
                        <List className={classes.li} onClick={handleClick}>
                          <ListItem
                            className={classes.li}
                            button
                            component="li"
                            onClick={handleClick}
                          >
                            <Link
                              className={classes.nested}
                              to={submenu.path}
                              activeClassName={classes.activeClass}
                            >
                              <ListItemText primary={submenu.name} />
                            </Link>
                          </ListItem>
                        </List>
                      </Collapse>
                    );
                  })}
            </React.Fragment>
          );
        })}
      </List>
    </>
  );
  return (
    <nav className={classes.drawer} aria-label="Mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={openDrawer}
          onClose={() => close()}
          classes={{
            paper: classes.drawerPaper
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
