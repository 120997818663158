import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import AppHeader from "../Header/Header";
import CookiesConsent from "../CookiesConsent/CookiesConsent";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: theme.mixins.toolbar,
  content: {
    width: "calc(100vw - 240px)",
    flexGrow: 1,
    padding: theme.spacing(3)
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppHeader />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Paper>{children}</Paper>
        <CookiesConsent />
      </main>
    </div>
  );
};

export default Layout;
